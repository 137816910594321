.card-center {
    height: calc(30vh - 16px);
    display: flex; 
    justify-content: center; 
    align-items: center;
}
.card-normal {
    height: calc(70vh - 24px);
    padding: 8px 0;
    text-align: left;
}
.data-table td, .data-table th {
   border: 0;
   font-size: 20px;
}

.data-table tr {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.green {
    color: green;
}

.red {
    color: red;
}