body {
  background-color: #F4F7FA;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card span {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.375;
}

.row {
  display: flex;
  flex-direction: row;
}

.between {
  justify-content: space-between;
}

.btn {
  cursor: pointer;
}

.btn-primary {
  background-color: #D61B34 !important;
  box-shadow: none !important;
}

.card-content p {
  margin: 0;
}

.bg-grey {
  background: #f1f1f1;
}

.card {
  text-align: left;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%) !important;
}

.card-header {
  border-bottom: 1px solid #f1f1f1;
}

.card-body {
  padding: 15px;
}

.card-title {
  font-size: 14px;
  margin: 0;
  color: #333748;
}

.card-subtitle {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  color: #333748;
}

.data-grid {
  border: 0 !important;
}