.content {
    padding: 20px;
}

.selected-item {
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.selected-item  p {
    margin: 5px 0;
}