ul {
    list-style: none;
    padding: 0;
}

ul li {
    text-align: left;
    margin-bottom: 5px;
    display: flex;
    padding: 9px 20px;
    border-radius: 8px;
}

ul li.active,
ul li:hover {
    background-color: rgba(0,0,0, 0.08);
}

ul li a {
    display: block;
    text-decoration: none;
    font-size: 16px;
    color: #fff;
    padding-left: 10px;
    width: 100%;
}

ul li button {
    display: block;
    font-size: 16px;
    color: #fff;
    border: 0;
    background: none;
    padding: 0;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: left;
    padding-left: 10px;
}

.sidebar {
    position: fixed;
    padding: 20px 0;
    background: #333748;
    min-width: 280px;
    height: 100vh; 
    box-shadow: 1px 0 20px 0 #3f4d67;
}

.sidebar nav {
    padding: 0 20px;
}

.logo {
    width: 100%;
    max-width: 100px;
    margin-bottom: 50px;
}